import styled, { css } from "styled-components";

export const IconBlockStandardWrapper = styled.div`
  font-family: 'Roboto';
  background: #f2f4f7;
`

export const IconBlockContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 96px 108px;
  @media (max-width: 992px) {
    padding: 96px 80px;
  }
  @media (max-width: 576px) {
    padding: 96px 35px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
  padding-top: ${noTopPadding === true && 0};
  padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const IconBlockHeading = styled.h2`
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  text-align: center;
  color: #1B1B1B;
  @media (max-width: 576px) {
    font-size: 32px;
    line-height: 42px;
  }
`

export const IconBlockSubheading = styled.h2`
  font-weight: 400;
  font-size: 22ppx;
  line-height: 42px;
  text-align: center;
  color: #475467;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 64px auto 0;
  max-width: 928px;
  @media (max-width: 992px) {
    max-width: 772px;
    flex-wrap: wrap;
  }
  @media (max-width: 576px) {
    max-width: 418px;
  }
`

export const IconItem = styled.a`
  display: flex;
  align-items: center;
  width: 64px;
  height: 64px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 992px) {
    max-width: 772px;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    &:nth-child(n+6) {
      display: none; 
    }
  }
  @media (max-width: 576px) {
    &:nth-child(n+5) {
      display: none; 
    }
  }
`